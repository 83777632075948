import {
	SignedIn,
	SignedOut,
	useAuth,
	useOrganization,
} from '@clerk/clerk-react';
import { Sonner, useTheme } from '@uturn/ui-kit';
import PublicRoutes from './routes/public';
import HttpClientProvider from './http/http-client-provider';
import FinanceApp from './modules/finance/app';
import OperationsApp from './modules/operations/app';
import NoBackofficeTeam from './page/no-backoffice-team';
import { useEffect } from 'react';

const App = () => {
	const { isSignedIn, isLoaded } = useAuth();
	const { theme } = useTheme();
	const { organization } = useOrganization();

	useEffect(() => {
		if (isLoaded && !isSignedIn && window.location.pathname !== '/') {
			window.location.href = '/?redirect=' + window.location.pathname;
		}
	}, [isLoaded, isSignedIn]);

	if (!isLoaded) {
		return null;
	}

	return (
		<HttpClientProvider>
			<Sonner
				richColors
				closeButton
				theme={theme.mode}
				position="bottom-center"
			/>
			<SignedIn>
				{{
					finance: <FinanceApp />,
					operations: <OperationsApp />,
					'no-organization': <NoBackofficeTeam />,
				}[organization?.slug ?? 'no-organization'] ?? <NoBackofficeTeam />}
			</SignedIn>
			<SignedOut>
				<PublicRoutes />
			</SignedOut>
		</HttpClientProvider>
	);
};

export default App;
